exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-australia-student-visa-support-js": () => import("./../../../src/pages/australia-student-visa-support.js" /* webpackChunkName: "component---src-pages-australia-student-visa-support-js" */),
  "component---src-pages-australia-wh-visa-support-js": () => import("./../../../src/pages/australia-wh-visa-support.js" /* webpackChunkName: "component---src-pages-australia-wh-visa-support-js" */),
  "component---src-pages-canada-student-visa-support-js": () => import("./../../../src/pages/canada-student-visa-support.js" /* webpackChunkName: "component---src-pages-canada-student-visa-support-js" */),
  "component---src-pages-canada-wh-visa-support-js": () => import("./../../../src/pages/canada-wh-visa-support.js" /* webpackChunkName: "component---src-pages-canada-wh-visa-support-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact-thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-es-wh-visa-support-js": () => import("./../../../src/pages/es-wh-visa-support.js" /* webpackChunkName: "component---src-pages-es-wh-visa-support-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-input-js": () => import("./../../../src/pages/input.js" /* webpackChunkName: "component---src-pages-input-js" */),
  "component---src-pages-input-uk-js": () => import("./../../../src/pages/input-uk.js" /* webpackChunkName: "component---src-pages-input-uk-js" */),
  "component---src-pages-ireland-promo-js": () => import("./../../../src/pages/ireland-promo.js" /* webpackChunkName: "component---src-pages-ireland-promo-js" */),
  "component---src-pages-ireland-wh-visa-support-js": () => import("./../../../src/pages/ireland-wh-visa-support.js" /* webpackChunkName: "component---src-pages-ireland-wh-visa-support-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-nz-wh-visa-support-js": () => import("./../../../src/pages/nz-wh-visa-support.js" /* webpackChunkName: "component---src-pages-nz-wh-visa-support-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-short-term-study-visa-support-js": () => import("./../../../src/pages/short-term-study-visa-support.js" /* webpackChunkName: "component---src-pages-short-term-study-visa-support-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-pages-theseaofocean-authors-js": () => import("./../../../src/pages/theseaofocean-authors.js" /* webpackChunkName: "component---src-pages-theseaofocean-authors-js" */),
  "component---src-pages-theseaofocean-js": () => import("./../../../src/pages/theseaofocean.js" /* webpackChunkName: "component---src-pages-theseaofocean-js" */),
  "component---src-pages-uk-junior-support-js": () => import("./../../../src/pages/uk-junior-support.js" /* webpackChunkName: "component---src-pages-uk-junior-support-js" */),
  "component---src-pages-uk-lp-js": () => import("./../../../src/pages/uk-lp.js" /* webpackChunkName: "component---src-pages-uk-lp-js" */),
  "component---src-pages-uk-promo-js": () => import("./../../../src/pages/uk-promo.js" /* webpackChunkName: "component---src-pages-uk-promo-js" */),
  "component---src-pages-uk-student-visa-support-js": () => import("./../../../src/pages/uk-student-visa-support.js" /* webpackChunkName: "component---src-pages-uk-student-visa-support-js" */),
  "component---src-pages-verb-list-js": () => import("./../../../src/pages/verb-list.js" /* webpackChunkName: "component---src-pages-verb-list-js" */),
  "component---src-pages-yms-visa-support-js": () => import("./../../../src/pages/yms-visa-support.js" /* webpackChunkName: "component---src-pages-yms-visa-support-js" */),
  "component---src-templates-blogpost-template-js": () => import("./../../../src/templates/blogpost-template.js" /* webpackChunkName: "component---src-templates-blogpost-template-js" */),
  "component---src-templates-cat-template-js": () => import("./../../../src/templates/cat-template.js" /* webpackChunkName: "component---src-templates-cat-template-js" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/city-template.js" /* webpackChunkName: "component---src-templates-city-template-js" */),
  "component---src-templates-country-template-js": () => import("./../../../src/templates/country-template.js" /* webpackChunkName: "component---src-templates-country-template-js" */),
  "component---src-templates-faq-template-js": () => import("./../../../src/templates/faq-template.js" /* webpackChunkName: "component---src-templates-faq-template-js" */),
  "component---src-templates-faqcat-template-js": () => import("./../../../src/templates/faqcat-template.js" /* webpackChunkName: "component---src-templates-faqcat-template-js" */),
  "component---src-templates-jr-summerschool-template-js": () => import("./../../../src/templates/jr-summerschool-template.js" /* webpackChunkName: "component---src-templates-jr-summerschool-template-js" */),
  "component---src-templates-key-template-js": () => import("./../../../src/templates/key-template.js" /* webpackChunkName: "component---src-templates-key-template-js" */),
  "component---src-templates-school-template-js": () => import("./../../../src/templates/school-template.js" /* webpackChunkName: "component---src-templates-school-template-js" */),
  "component---src-templates-word-template-js": () => import("./../../../src/templates/word-template.js" /* webpackChunkName: "component---src-templates-word-template-js" */),
  "component---src-templates-wordcat-template-js": () => import("./../../../src/templates/wordcat-template.js" /* webpackChunkName: "component---src-templates-wordcat-template-js" */),
  "component---src-templates-wordrank-template-js": () => import("./../../../src/templates/wordrank-template.js" /* webpackChunkName: "component---src-templates-wordrank-template-js" */)
}

